import axios from "axios";
import qs from "qs";
import {
  LOGIN_URL,
  REGISTER_URL,
  GET_USER_INFO_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  GET_ORGANIZATION_INFO,
  MASTER_TRAINER_URL,
  PATCH_USER_FIRSTTIME_URL,
  LINE_GRAPH_URL,
  BASE_URL,
} from "../constants/urls";

const buildUrlParams = (url, params) => {
  return `${url}?${qs.stringify(params)}`;
};

export const login = (payload) => axios.post(LOGIN_URL, payload);

export const register = (payload) => axios.post(REGISTER_URL, payload);

export const fetchUserInfo = () => axios.get(GET_USER_INFO_URL);

export const forgetPassword = (payload) =>
  axios.post(FORGOT_PASSWORD_URL, payload);

export const resetPassword = (payload) =>
  axios.post(RESET_PASSWORD_URL, payload);

export const fetchOrganizationInfo = (orgId) =>
  axios.get(`${GET_ORGANIZATION_INFO}/${orgId}`);

export const fetchMasterTrainer = (payload, url) =>
  axios.get(buildUrlParams(url, payload));

export const pagination = (url, params, payload) =>
  axios.post(buildUrlParams(url, params), payload, { Headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

export const ExcelDownloaderAPI = (url, params, payload) => {
  return axios.post(buildUrlParams(url, params), payload, { responseType: 'blob', Headers: { Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" } })
}

export const patchUserFirstTimeUser = (userId, payload) =>
  axios.patch(`${PATCH_USER_FIRSTTIME_URL}/${userId}`, payload);

export const lineGraphData = () => axios.get(LINE_GRAPH_URL);
export const loginWithToken = (token) =>
  axios.post(
    `${BASE_URL}/auth/loginWithToken`,
    {},
    { headers: { "Authorization": `Bearer ${encodeURIComponent(token)}` } }
  );
export const fetchEndPoint = async (url) => {
  let requestOptions = {
    method: 'GET',
    headers: { "Authorization": "Bearer t", "Content-Type": `application/json` },
    body: null,
    redirect: 'follow'
  }
  const res = await fetch(url, requestOptions).then(response => response.json()).catch(err => console.log(err));
  return res
}
