import React from "react";

function ComingSoon({ message }) {
  return (
    <React.Fragment>
      <div style={{ position: 'absolute', top: "30vh", width: "100%" }} className="welcome">
        <div className="line">{message}</div>
      </div>
    </React.Fragment>
  );
}

export default ComingSoon;
