import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJs.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

// const totalIncrease = existingData.reduce(
//   (total, currentValue, index, array) => {
//     if (index > 0) {
//       const increase = currentValue - array[index - 1];
//       return total + increase;
//     }
//     return total;
//   },
//   0
// );

// const averageIncreaseRate = totalIncrease / (existingData.length - 1);

// let newData = [...existingData];
// let lastValue = existingData[existingData.length - 1];

// for (let i = 0; i < numberOfMonthsRequired; i++) {
//   lastValue += averageIncreaseRate;
//   newData.push(Math.round(lastValue));
// }
// data.datasets[2].data = newData;

const LineChart = (props) => {
  console.log("propsInLineChart", props);
  const label = props.label;
  const dataSets = props.dataSet;
  // console.log("label", label);
  // console.log("dataSets", dataSets);
  const idealProgress = dataSets[0];
  const actualProgress = dataSets[1];
  let projectedProgress = dataSets[2];
  const projectedLabel = dataSets[2]?.label;
  const priorityData = dataSets[3]?.data;
  projectedProgress = projectedProgress?.data;
  // console.log("projectedLabel", projectedLabel);
  // const milestoneDates = newLabel.filter(
  //   (date, index) => priority[index] === "Milestone"
  // );
  const newProjectedData = projectedProgress?.map((data, index) => {
    if (projectedProgress[index] == "null") {
      return null;
    }
    return data;
  });

  // console.log("newProjectedData", newProjectedData);
  const labels = label?.map((date, index) => {
    if (priorityData[index] === "normal") {
      return "";
    }
    return date;
  });

  // console.log("labelUpdated", labels);

  const data = {
    labels: labels,
    datasets: [
      {
        label: idealProgress?.label,
        data: idealProgress?.data,
        backgroundColor: "green",
        borderColor: "green",
        pointBorderColor: "green",
      },
      {
        label: actualProgress?.label,
        data: actualProgress?.data,
        backgroundColor: "red",
        borderColor: "red",
        pointBorderColor: "red",
      },
      {
        label: projectedLabel,
        data: newProjectedData,
        backgroundColor: "transparent",
        borderColor: "crimson",
        borderDash: [5, 5],
        pointBorderColor: "crimson",
      },
    ],
  };
  const existingData = data?.datasets[1]?.data;
  // console.log('existingData', existingData)
  // console.log("existingData", existingData.length);
  const calculateAverageMonthlyRunRate = (data) => {
    const totalIncreaseInData =
      existingData[existingData?.length - 1] - existingData[0];

    const numberOfMonths = existingData?.length - 1;
    // console.log("numberOfMonths", numberOfMonths);
    const averageMonthlyRunRate = totalIncreaseInData / numberOfMonths;
    return averageMonthlyRunRate;
  };
  const getMonthlyRunRate = calculateAverageMonthlyRunRate(existingData);

  const idealProgressData = data.datasets[0]?.data;
  const numberOfMonthsRequired =
    idealProgressData?.length - existingData?.length;
  // console.log("numberOfMonthsRequired", numberOfMonthsRequired);
  // console.log("idealProgressData", idealProgressData.length);
  const getRequiredRunRate = (data) => {
    // console.log("dataInFunction", data?.length);
    const totalRequiredProgress =
      idealProgressData[idealProgressData?.length - 1];
    // console.log("totalRequiredProgress", totalRequiredProgress);
    const numberOfMonthsRequired = data?.length - existingData?.length;
    // console.log("numberOfMonthsRequiredInFunction", numberOfMonthsRequired);
    const requiredRunRate = Math.round(
      (totalRequiredProgress - existingData[existingData.length - 1]) /
        numberOfMonthsRequired
    );
    return requiredRunRate;
  };

  const requiredRunRatePerMonth = getRequiredRunRate(idealProgressData);
  const lastDataIndex = data.datasets[1].data.length - 1;

  const options = {
    plugins: {
      legend: {
        labels: {
          display: false,
          filter: (item) => !item.text.includes("Projected"),
        },
      },
      customLines: {
        vertical: [
          {
            x: lastDataIndex,
            borderColor: "rgba(0, 0, 0, 1)",
            borderDash: [5, 5],
            label: {
              content: `We are here`,
              enabled: true,
              position: "top",
            },
          },
        ],
      },
    },
    scales: {
      x: {
        grid: {
          color: "grey",
          lineWidth: (context) => {
            if (context.chart.data.labels[context.tick.value]?.length > 0) {
              return 2;
            } else {
              return 0.1;
            }
          },
          borderColor: "grey",
          tickColor: "grey",
          color: (context) => {
            if (lastDataIndex == context.tick.value) {
              return false;
            } else if (
              context.chart.data.labels[context.tick.value]?.length > 0
            ) {
              return true;
            } else {
              return true;
            }
          },
        },
        ticks: {
          color: "grey",
        },
        title: {
          display: true,
          text: "Project MileStone",
        },
      },
      y: {
        suggestedMin: 0,
        title: {
          display: true,
          text: "Participants Cerified",
        },
      },
    },
  };
  const drawLinesPlugin = {
    id: "drawLines",
    afterDraw: (chart) => {
      const line = chart.options.plugins.customLines.vertical[0];
      const xScale = chart.scales.x;
      const yScale = chart.scales.y;
      const ctx = chart.ctx;

      if (line) {
        const lastDataIndex = data.datasets[1].data.length - 1;
        const x = xScale.getPixelForValue(data.labels[lastDataIndex]);
        const yTop = chart.chartArea.top + 30;
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = line.borderColor;
        ctx.lineWidth = 2;
        ctx.setLineDash(line.borderDash || []);
        ctx.moveTo(x, yTop);
        ctx.lineTo(x, chart.chartArea.bottom);
        ctx.stroke();
        ctx.restore();
        ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
        ctx.font = "18px Arial";
        const textWidth = ctx.measureText(line.label.content).width;
        // ctx.fillText(
        //   line.label.content,
        //   x - textWidth - 10,
        //   chart.chartArea.bottom - 10
        // );
        ctx.fillText(line.label.content, x - textWidth / 2, yTop + 10);
      }
    },
  };

  ChartJs.register(drawLinesPlugin);

  const divStyle = {
    backgroundColor: "lightgray",
    padding: "1rem",
    width: "30%",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginBottom: "7rem",
  };

  const textStyle = {
    fontSize: "12px",
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      {/* <h1>Line chart</h1> */}
      <div
        style={{
          width: "70%",
          marginBottom: "5%",
          marginLeft: "-3rem",
        }}
      >
        <Line data={data} options={options} />
      </div>
      <div style={divStyle}>
        <span style={textStyle}>
          Current Run Rate:{" "}
          <strong>{getMonthlyRunRate?.toFixed(2)} /week</strong>
        </span>
        <br />
        {getMonthlyRunRate < requiredRunRatePerMonth ? (
          <p style={textStyle}>
            At this rate we will not be able to complete the program as per
            timelines
          </p>
        ) : (
          <span style={textStyle}> Everything is on track</span>
        )}
        <span style={textStyle}>
          Required Run Rate:{" "}
          <strong>{requiredRunRatePerMonth?.toFixed(2)} /week</strong>
        </span>
      </div>
    </div>
  );
};

export default LineChart;
