import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Header, Sidebar, Dashboard, ViewAll, Footer, Auth, Snapshot, ViewDetails, } from "./components";
import "./App.scss";
import { AppStateContext } from "./providers/app.provider";
import { toUrl } from "./utils/common";
import Analytics from "./components/analytics";
import Master from "./components/masterTrainer";
import { useLocation } from "react-router-dom";
import { ANALYTICS_URL } from "./constants/urls";
import BatchWise from "./components/batchWise";
import FlashPointsAnalytics from "./components/dashboard/flashAnalytics";


const App = (props) => {
  const [state] = useContext(AppStateContext);
  const [show, setShow] = useState(true);
  const [url, setUrl] = useState(ANALYTICS_URL);
  const [pageConfig, setPageConfig] = useState(null);
  const [report, setReport] = useState(null);

  const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const ud = params.get('ud');



  const wLocation = window.location;
  const key = location.hash;
  let _URL = ANALYTICS_URL;
  const menu = state?.menu || {};
  const menus = Object.values(menu);

  const ud = menus?.[0]?.[0]?.isUsedByParent

  useEffect(() => {
    if (location.hash || wLocation.hash) {
      console.log("hash ==> ", location.hash, wLocation.hash);
      console.log("menu ==> ", menus);
      menus.forEach((reports) => {
        reports.forEach((r) => {
          const que = r.question;
          que.find((q) => {
            if (toUrl(q.que_text) === key.substring(1)) {
              _URL = _URL.concat(q.api);
              setUrl(_URL);
              setPageConfig(q);
            }
          });
        });
      });
    }
  }, [location, wLocation, state]);



  const renderRedirect = () => {
    const categories = Object.keys(state.menu);

    if (categories.length > 0) {
      return (
        <Redirect
          exact
          from="/"
          to={toUrl(`/${categories[0]}/${state.menu[categories[0]][0].dashboardName}`)}
        />
      );
    }
    return <></>;
  };
  let { showBatchAnalytics } = pageConfig || {}
  let page = location.pathname.includes('batchWise')

  if (state.isLoggedIn) {
    return (
      <>
        <Header ud={ud} />
        <Sidebar show={show} ud={ud} />
        <div
          className={ud ? 'RenderToParent' : "content-wrapper"}
          style={{
            padding: show ? '' : showBatchAnalytics && report ? "5% 0px 85px" : "6% 15px 15px 85px",
            margin: showBatchAnalytics && report ? '0px' : '0 auto',
            width: showBatchAnalytics && report ? '100%' : '90%'
          }}
        >
          <Switch>
            <Redirect exact from="/login" to="/" />
            {renderRedirect()}
            <Route path="*/view-all/:widgetName" component={ViewAll} />
            <Route path='*/batchWise'
              component={() => { return <BatchWise show={show} setShow={setShow} pageConfig={pageConfig} /> }} />
            <Route path="/flashpointanalytics" component={FlashPointsAnalytics} />
            <Route
              path="*/details/:widgetName"
              component={() => (
                <ViewDetails
                  show={show}
                  ud={ud}
                  report={report}
                  setReport={setReport}
                  setShow={setShow}
                  url={url}
                  appState={state}
                  pageConfig={pageConfig}
                />
              )}
            />

            <Route path="/snapshot" component={Snapshot} />
            <Route path="/master" component={Master} />
            <Route
              path="*"
              component={() => (
                <Dashboard setShow={setShow} url={url} widget={pageConfig} />
              )}
            />
          </Switch>
        </div >
        <Footer />
        {/* </Switch> */}
      </>
    );
  }

  return <Auth />;
};

export default App;
