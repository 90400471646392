import { Button } from '@material-ui/core';
import React from 'react';
import * as XLSX from 'xlsx';

const ExcelDownloader = ({ getExcel }) => {
    const downloadExcel = () => {
        const getData = async () => getExcel().then(res => {
            if (res?.length > 0) {
                const ws = XLSX.utils.json_to_sheet(res);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
                XLSX.writeFile(wb, 'report.xlsx');
            }
        })
        getData()
        
    };

    return (
        <Button className="close-btn-w" style={{ background: "#ba1f00", color: "white" }} onClick={downloadExcel}>
            Download Excel
        </Button>
    );
};

export default ExcelDownloader;
