import React, { useEffect, useContext, useState, useMemo } from "react";
import { FormControl, MenuItem, Select, Button, IconButton, ButtonGroup, CircularProgress, } from "@material-ui/core";
import { Info, ArrowRight, ChevronRight } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { toUrl } from "../../utils/common";
import "./styles.scss";
import { fetchEndPoint, fetchMasterTrainer, fetchUserInfo, lineGraphData, } from "../../services/auth";
import CustomTable from "../partials/table";
import CustomModal from "../partials/modal";
import createDOMPurify from "dompurify";
import _ from "lodash";
import Download from "../dashboard/details/Download";
import BarChart from "../charts/BarChart";
import LineChart from "../charts/LineChart";
import { useClientContext } from "../../store/ClientProvider";
import { AppStateContext } from "../../providers/app.provider";
const DOMPurify = createDOMPurify(window);

function MasterTrainer(props) {
  const { showInfo, showFilters, url, periods, datakey, newApiEndPoint, ...pageConfig } = props;
  const { snapshotGraph } = props;
  const location = useLocation();
  const { client, updateClient } = useClientContext();
  const [, dispatch] = useContext(AppStateContext);
  const { showTime, toogleTable, defaultStates, snapShotInfo } = pageConfig || {};

  const [, setHeading] = useState("");
  const [summary, setSummary] = useState([]);
  const [headCells, setHeadCells] = useState([]);
  const [open, setOpen] = useState({ state: false, value: null });

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [page, setPage] = React.useState(0);

  const [whatShow, setWhatShow] = useState(false);
  const [, setFieldStaff] = useState(null);
  const [snapshot, setSnapshot] = useState(null);

  const [college, setCollege] = useState("");
  const [colleges, setColleges] = useState([]);

  const [division, setDivision] = useState("");
  const [divisions, setDivisions] = useState([]);

  const [region, setRegion] = useState("");
  const [regions, setRegions] = useState([]);

  const [faculty, setFaculty] = useState("");
  const [facultys, setFacultys] = useState([]);

  const [batches, setBatches] = useState([]);
  const [batch, setBatch] = useState("");

  const [districts, setDistricts] = useState([]);
  const [district, setDistrict] = useState("");
  const [policeStation, setPoliceStation] = useState("");
  const [policeStations, setPoliceStations] = useState([]);

  const [state, setState] = useState("");
  const [states, setStates] = useState([]);

  const [period, setPeriod] = useState(periods?.[0]?.value || "level");
  const [collectionname] = useState(props?.collectionname);
  const [report] = useState(props?.report);
  const [clientData] = useState(props?.client);

  const [lineLabels, setLineLabels] = useState([]);
  const [lineDatasets, setLineDatasets] = useState([]);

  const [accesscode, setAccesscode] = useState("");
  const [collegename, setCollegename] = useState("");

  const [trainer, setTrainer] = useState("");
  const [trainers, setTrainers] = useState([]);
  const [showInfoData, setShowInfoData] = useState(true);
  const [selectedButton, setSelectedButton] = useState("");
  const [height, setHeight] = useState(0);

  const [toogleTableData, setToogleTableData] = useState(null)
  const [currentView, setCurrentView] = useState(Object.keys(toogleTable || {})[0] || '')

  const [time, setTime] = useState('')

  const [dynamicFilter, setDynamicFilter] = useState({ ...defaultStates })

  const handleButtonClick = (buttonName) => {
    if (buttonName === selectedButton) {
      setSelectedButton("");
      setShowInfoData(false);
    } else {
      setSelectedButton(buttonName);
      setShowInfoData(true);
    }
  };

  useEffect(() => {
    let newHeight =
      client === "CSC"
        ? localStorage.setItem("heightUpdated", 540)
        : client === "DP"
          ? localStorage.setItem("heightUpdated", 960)
          : 0;
  }, [client]);

  const organizationName = pageConfig?.organisationName;

  const getUserInfo = async () => {
    return fetchUserInfo().then((data) => {
      setAccesscode(data.data.accesscode);
      let col = data.data.meta?.bput?.college_code?.split(/[,]+/);
      let newCol = col?.[0]?.replace(/ /g, "+");
      setCollegename(newCol);
    });
  };

  // const getLineGraphData = async () => {
  //   const results = await lineGraphData();
  //   let lineLabel = results?.data?.labels;
  //   let lineDataset = results?.data?.datasets;
  //   setLineLabels(lineLabel);
  //   setLineDatasets(lineDataset);
  // };

  useEffect(async () => {
    // await getLineGraphData();
    if (organizationName == "BPUT") {
      await getUserInfo();
      if (location.hash && collegename && accesscode) {
        setHeading(location.hash);
        const payload = {
          view: "summary",
          period,
          collectionname,
          accesscode,
          collegename,
          report,
          datakey,
          client: client,
        };
        getMasterTrainer(payload, url);
      }
    } else {
      if (location.hash) {
        setHeading(location.hash);
        const payload = {
          view: "summary",
          period,
          collectionname,
          accesscode,
          collegename,
          datakey,
          report,
          client: client,
        };
        getMasterTrainer(payload, url);
      }
    }
  }, [location.hash, period, collegename, client, toogleTable]);

  const user = JSON.parse(localStorage.getItem("user"));
  const splitKey = (key) => {
    const _key = (user.organizationid = "6123add4dfd0fbe63095d7c7"
      ? key.split("  ")
      : key.split("("));
    const keys = {
      id: _key[0],
      label: _key[0].trim(),
      caption: _key[1] ? "(".concat(_key[1]) : null,
    };

    return keys;
  };

  const processHead = (arr, sum) => {
    let arr2 = [];
    if (Object.keys(sum || {}).length > 0) {
      const heads1 = [sum].map((k) => {
        let arr1 = [];
        for (let i = 0; i < Object.keys(k).length; i++) {
          arr1 = Object.assign(
            {
              originalKey: Object.keys(k)[i],
              numeric:
                // (Object.values(k)[i] || [])?.includes('%') ? false :
                typeof (Object.values(k)[i]) == 'string' ? true : isNaN(Object.values(k)[i]),
              disablePadding: false,
            },
            splitKey(Object.keys(k)[i])
          );
          arr2.push(arr1);
        }
      });
      if (arr != 'arr')
        setHeadCells(arr2);
      return arr2;
    }
  };

  const handleChange = (e, item) => {
    const value = e.target.value;
    switch (item) {
      case "faculty":
        setFaculty(value);
        break;
      case "college":
        setCollege(value);
        break;
      case "batch":
        setBatch(value);
        break;
      case "region":
        setRegion(value);
        break;
      case "division":
        setDivision(value);
        break;
      case "district":
        setDistrict(value);
        break;
      case "policeStation":
        setPoliceStation(value);
        break;
      case "state":
        setState(value);
        break;
      case "trainer":
        setTrainer(value);
        break;
      default:
        break;
    }
  };

  const getMasterTrainer = (payload, URL) => {
    newApiEndPoint ? fetchEndPoint(`${newApiEndPoint}?view=summary`)
      .then((res) => {
        let data = { data: { snapshot: res?.snapshot, data: res?.data } }
        // if (typeof data.data !== "object") data.data = JSON.parse(data.data);
        const _labels = data.data.labels;
        const _datasets = data.data.datasets;
        const _summary = data.data.data;
        const fieldStaff = data.data.field_staff;
        const _snapshot = data.data.snapshot;
        const _time = data.data.time

        const _batches = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Batch)),
          (b) => b
        ).sort();
        const _facultys = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["Faculty Name"])),
          (f) => f
        );
        const _colleges = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.College)),
          (d) => d
        );
        const _divisions = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Division)),
          (d) => d
        );
        const _regions = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Region)),
          (d) => d
        );
        const _districts = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["District/Units"])),

          (d) => d
        );
        const _policeStation = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["Police Station"])),
          (d) => d
        );
        const _state = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.State)),
          (d) => d
        );
        const _trainer = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Trainer)),
          (d) => d
        );

        setTrainers(_trainer);
        setStates(_state);

        setRegions(_regions);
        setDivisions(_divisions);

        setLabels(_labels);
        setDatasets(_datasets);
        setPoliceStations(_policeStation);
        setDistricts(_districts);

        setSummary(data.data.data);
        setFieldStaff(fieldStaff);
        setSnapshot(_snapshot);
        setBatches(_batches);
        setColleges(_colleges);
        setFacultys(_facultys);
        setTime(_time)
        if (toogleTable)
          setToogleTableData(data.data.data)
        else {
          if (_summary?.length > 0) {
            processHead(Object.keys(_summary[0]), _summary[0]);
          }
        }
      }).catch((err) => {
        setWhatShow(true);
        console.error(err);
      }) :
      fetchMasterTrainer(payload, url)
        .then((data) => {
          if (typeof data.data !== "object") data.data = JSON.parse(data.data);
          const _labels = data.data.labels;
          const _datasets = data.data.datasets;
          const _summary = data.data.data;
          const fieldStaff = data.data.field_staff;
          const _snapshot = data.data.snapshot;
          const _time = data.data.time

          const _batches = _.filter(
            _.uniq(_.map(_summary, (_s) => _s.Batch)),
            (b) => b
          ).sort();
          const _facultys = _.filter(
            _.uniq(_.map(_summary, (_s) => _s["Faculty Name"])),
            (f) => f
          );
          const _colleges = _.filter(
            _.uniq(_.map(_summary, (_s) => _s.College)),
            (d) => d
          );
          const _divisions = _.filter(
            _.uniq(_.map(_summary, (_s) => _s.Division)),
            (d) => d
          );
          const _regions = _.filter(
            _.uniq(_.map(_summary, (_s) => _s.Region)),
            (d) => d
          );
          const _districts = _.filter(
            _.uniq(_.map(_summary, (_s) => _s["District/Units"])),

            (d) => d
          );
          const _policeStation = _.filter(
            _.uniq(_.map(_summary, (_s) => _s["Police Station"])),
            (d) => d
          );
          const _state = _.filter(
            _.uniq(_.map(_summary, (_s) => _s.State)),
            (d) => d
          );
          const _trainer = _.filter(
            _.uniq(_.map(_summary, (_s) => _s.Trainer)),
            (d) => d
          );

          setTrainers(_trainer);
          setStates(_state);

          setRegions(_regions);
          setDivisions(_divisions);

          setLabels(_labels);
          setDatasets(_datasets);
          setPoliceStations(_policeStation);
          setDistricts(_districts);

          setSummary(data.data.data);
          setFieldStaff(fieldStaff);
          setSnapshot(_snapshot);
          setBatches(_batches);
          setColleges(_colleges);
          setFacultys(_facultys);
          setTime(_time)
          if (toogleTable)
            setToogleTableData(data.data.data)
          else {
            if (_summary?.length > 0) {
              processHead(Object.keys(_summary[0]), _summary[0]);
            }
          }
        })
        .catch((err) => {
          setWhatShow(true);
          console.error(err);
        });
  };



  const viewAllArgs = useMemo(() => {
    return {
      pathname: toUrl(
        `${location.pathname}/details/widgetname?ud=${newApiEndPoint ? 1 : 0}${location.hash}`
      ),
    };
  }, []);

  const filter = (rows) => {
    let _filter = {
      College: college === "" ? undefined : college,
      Division: division === "" ? undefined : division,
      Region: region === "" ? undefined : region,
      ["Faculty Name"]: faculty === "" ? undefined : faculty,
      Batch: batch === "" ? undefined : batch,
      ["District/Units"]: district === "" ? undefined : district,
      ["Police Station"]: policeStation === "" ? undefined : policeStation,
      State: state === "" ? undefined : state,
    };
    _filter = _.omitBy(_filter, _.isUndefined);
    return _.filter(rows, _filter);
  };


  function toogleReturnRows(arr, obj) {
    function filterArrayByObject(arr, filterObj) {
      if (arr?.length > 0)
        return arr.filter(item => {
          for (const key in filterObj) {
            if (filterObj[key].length > 0 && item[key]) {
              if (key == 'Trainer') {
                if (item['Coach-1 Name'] !== filterObj['Trainer'] && item['Coach-2 Name'] !== filterObj['Trainer']) {
                  return false
                }
              }
              else {
                if (item[key] !== filterObj[key]) {
                  return false;
                }
              }
            }
          }
          return true;
        });
    }

    let filter = obj ? obj : dynamicFilter
    return filterArrayByObject(arr, filter);
  }

  function filterKeyValues(obj, n) {
    const filteredPairs = {};
    let count = 0;
    for (const key in obj) {
      if (count < n) {
        filteredPairs[key] = obj[key];
        count++;
      }
    }

    return filteredPairs;
  }

  function retFun() {
    if (snapshot == null && whatShow) {
      return (
        <div
          style={{
            position: "relative",
            top: "180px",
            textAlign: "center",
            fontWeight: 500,
            fontFamily: "'Fira Sans', sans-serif",
          }}
        >
          {props?.message}
        </div>
      );
    }
    if (snapshot == null || summary.length == 0) {
      return <div style={{ display: "flex", position: "fixed", left: "50%", top: '37%', flexGrow: 1, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <CircularProgress />
        <div style={{ margin: "0.5rem", textAlign: "center", color: "#999" }}>Loading...</div>
      </div>
    }
    else if (snapshot || labels || datasets != null) {
      return (
        <div>
          <section className="section master-trainer">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div className="heading-row mb-4" style={{ width: "78%" }}>
                <div className="heading">
                  {(pageConfig && pageConfig.heading) || ""}
                  <IconButton
                    onClick={() => setOpen({ state: true, value: pageConfig.info })}
                    style={{ padding: "0.6%" }}
                  >
                    <Info />
                  </IconButton>
                </div>
                <div className="sub-heading ">
                  {pageConfig && pageConfig?.subHeading}
                </div>
                {showTime && <div style={{ color: 'grey', fontSize: 14 }}>{`[Last Updated On: ${time}]`}</div>}
              </div>

              <div
                className="view-details"
                style={{ marginBottom: "4%", width: "35%" }}
              >
                <Download
                  rows={toogleTable ? toogleReturnRows(toogleTableData?.[currentView]) || [] : toogleReturnRows(summary) || []}
                  filename={pageConfig.heading}
                  headCells={headCells}
                  grandTotalColumns={pageConfig.grandTotalColumns}
                  emptyInfo={pageConfig.emptyInfo || 0}
                  emptyColumn={pageConfig.emptyColumn}
                  emptyCharacter={pageConfig.emptyCharacter || ""}
                />
              </div>
            </div>
            {snapshotGraph && false && (
              <ButtonGroup style={{ marginBottom: "1rem" }}>
                <Button
                  onClick={() => handleButtonClick("Certification Snapshot")}
                  variant={
                    selectedButton === "Certification Snapshot"
                      ? "contained"
                      : "outlined"
                  }
                >
                  Certification Snapshot
                </Button>
                <Button
                  onClick={() => handleButtonClick("Progress and Projection")}
                  variant={
                    selectedButton === "Progress and Projection"
                      ? "contained"
                      : "outlined"
                  }
                >
                  Progress and Projection
                </Button>
              </ButtonGroup>
            )}

            <div className="small-container">
              <>
                {props?.client && (
                  <FormControl className="select-form-control">
                    <Select
                      variant="outlined"
                      value={trainer}
                      onChange={(e) => {
                        const newClient = e.target.value;
                        updateClient(newClient);
                        const payload = {
                          view: "summary",
                          period,
                          collectionname,
                          accesscode,
                          collegename,
                          report,
                          client: newClient,
                        };
                        getMasterTrainer(payload, url);
                      }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                    >
                      <MenuItem value="">{client}</MenuItem>
                      {clientData
                        ?.filter((data) => data !== client)
                        ?.map((s, index) => {
                          return (
                            <MenuItem key={index} value={s}>
                              {s}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}

                {labels && datasets && false && (
                  <BarChart labelData={labels} datasetData={datasets} />
                )}
                {selectedButton === "Progress and Projection" && false ? (
                  <>
                    {snapshotGraph && snapshotGraph?.type === "Bar" ? (
                      <BarChart />
                    ) : (
                      <LineChart label={lineLabels} dataSet={lineDatasets} />
                    )}
                  </>
                ) :
                  (showInfo && snapshot && showInfoData && (
                    <div className="section-info-wrapper mb-5">
                      <div className="sec-heading">
                        <span>
                          <ArrowRight
                            fontSize="large"
                            style={{ fill: "#BA1F00" }}
                          />
                        </span>
                        <span className="text">Snapshot</span>
                      </div>
                      {Object.keys(snapshot).map((s) => (
                        <div className="section-info" key={s}>
                          <div
                            className="line left"
                            style={{ fontWeight: "normal" }}
                          >
                            {s} {snapShotInfo?.[s] && <Info style={{ fill: 'grey' }} onClick={() => {
                              setOpen({ state: true, value: snapShotInfo?.[s] })
                            }} />}
                          </div>
                          <div className="line numbers">
                            <span className="bold big" style={{ padding: "10%" }}>
                              {snapshot[s]}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                  )}
                {labels && datasets && (
                  <div className="parent">
                    <div className="left_red">
                      {pageConfig?.detailText ? (
                        <div className="right">
                          <Link

                            variant="contained"
                            color="primary"
                            to={viewAllArgs}
                            className="close-btn"
                          >
                            {pageConfig.detailText}

                            <ChevronRight />
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </>

              {pageConfig?.tableTitle && (
                <div className="table-wrapper">
                  <div className="table-head-row">
                    <div className="left">
                      <ArrowRight
                        fontSize="large"
                        style={{ fill: "#BA1F00" }}
                      />
                      {pageConfig.tableTitle}
                    </div>

                    {pageConfig?.actionableLinks?.length > 0 ?
                      <div className="right" style={{ display: 'flex', flexDirection: 'column' }}>
                        {pageConfig?.actionableLinks.map(_link => {
                          return <Link
                            style={{ padding: 0 }}
                            onClick={() => dispatch({ type: 'View', payload: { view: _link['view'] } })}
                            variant="contained"
                            color="primary"
                            to={viewAllArgs}
                            className="close-btn"
                          >
                            {_link.text} <ChevronRight />
                          </Link>
                        })}
                      </div> :
                      pageConfig?.detailText ? (
                        <div className="right">
                          <Link
                            onClick={() => dispatch({ type: 'View', payload: { view: 'detail' } })}
                            variant="contained"
                            color="primary"
                            to={viewAllArgs}
                            className="close-btn"
                          >
                            {pageConfig.detailText} <ChevronRight />
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}

                  </div>
                  <div style={{ margin: 2 }}>
                    {Object.keys(toogleTableData || {}).map(key => {
                      return <Button
                        style={{ margin: '2px 4px', border: currentView == key ? '2px solid black' : '' }}
                        onClick={() => {
                          setCurrentView(key)
                          setDynamicFilter({ ...defaultStates })
                        }}
                        key={key} variant="contained" >
                        {toogleTable?.[key]?.label}
                      </Button>
                    }
                    )}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '10px' }}>
                    {(pageConfig?.Filter || [])
                      .map((fil, index) => {
                        if (fil != 'Trainer') {
                          let obj = filterKeyValues(dynamicFilter, index)
                          if (obj[fil]) { delete obj[fil] }
                          let revisedRows = toogleReturnRows(summary, obj);
                          let options = [...new Set((revisedRows || []).filter(el => el?.[fil]?.length > 0).map(el => el?.[fil]))]
                          let value = defaultStates?.[fil] || dynamicFilter?.[fil] || ''

                          return <div className="control">
                            <div className="s-label">{fil}</div>
                            <div className="item-control">
                              <FormControl className="select-form-control">
                                <Select
                                  variant="outlined"
                                  value={value}
                                  onChange={(e) => {
                                    if (index == 0) { setDynamicFilter({ ...defaultStates }) }
                                    if (e.target.value.length == 0) {
                                      let obj = filterKeyValues(dynamicFilter, index)
                                      if (obj['Trainer']) delete obj['traienr']
                                      if (obj[fil]) delete obj[fil]
                                      setDynamicFilter({ ...defaultStates, ...obj })
                                    }
                                    else {
                                      if (dynamicFilter['Trainer']) setDynamicFilter((old) => ({ ...old, 'Trainer': '', [fil]: e.target.value }))
                                      else setDynamicFilter((old) => ({ ...old, [fil]: e.target.value }))
                                    }
                                  }}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label", }}
                                >
                                  {!defaultStates?.[fil] && <MenuItem value=''>All</MenuItem>}
                                  {options
                                    .sort((a, b) => {
                                      return a?.localeCompare(b);
                                    })
                                    .map((s, index) => {
                                      return (
                                        <MenuItem key={index} value={s}>
                                          {s}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        }
                        else {
                          let ob1 = filterKeyValues(dynamicFilter, index)
                          if (ob1['Trainer']) { delete ob1['Trainer'] }
                          let revisedRows = toogleReturnRows(summary, ob1) || []
                          let ae = []
                          revisedRows.map(el => {
                            if (el?.['Coach-1 Name']?.length > 0) {
                              ae.push(el?.['Coach-1 Name'])
                            }
                            if (el?.['Coach-2 Name']?.length > 0) {
                              ae.push(el?.['Coach-2 Name'])
                            }
                          })
                          let options = [...new Set(ae.filter(el => el?.[fil]?.length > 0).map(el => el?.[fil]))]

                          if (options?.length > 0)
                            return <div className="control">
                              <div className="s-label">{fil}</div>
                              <div className="item-control">
                                <FormControl className="select-form-control">
                                  <Select
                                    variant="outlined"
                                    value={dynamicFilter['Trainer'] || ''}
                                    onChange={(e) => {
                                      if (e.target.value.length > 0)
                                        setDynamicFilter((old) => ({ ...old, ['Trainer']: e.target.value }))
                                      else {
                                        let obj = filterKeyValues(dynamicFilter, index)
                                        setDynamicFilter({ ...defaultStates, obj })
                                      }
                                    }}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Without label", }}
                                  >
                                    {!defaultStates?.[fil] && <MenuItem value=''>All</MenuItem>}
                                    {options
                                      .sort((a, b) => {
                                        return a.localeCompare(b);
                                      })
                                      .map((s, index) => {
                                        return (
                                          <MenuItem key={index} value={s}>
                                            {s}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                        }

                      })}
                  </div>
                  {periods?.length > 0 && (
                    <div className="selection">
                      <div className="item-control">
                        <FormControl className="select-form-control">
                          <Select
                            variant="outlined"
                            value={period}
                            onChange={(e) => handleChange(e, "period")}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {periods
                              .sort((a, b) => {
                                return a.localeCompare(b);
                              })
                              .map((p) => (
                                <MenuItem value={p.value}>{p.text}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '10px' }}>
                    {(toogleTable?.[currentView]?.filters || []).map((fil, index) => {
                      let ob1 = filterKeyValues(dynamicFilter, index)
                      if (ob1[fil]) { delete ob1[fil] }
                      let revisedRows = toogleReturnRows(toogleTableData?.[currentView], ob1);
                      let options = [...new Set((revisedRows || []).filter(el => el?.[fil]?.length > 0).map(el => el?.[fil]))]
                      let value = dynamicFilter?.[fil] || defaultStates?.[fil] || ''

                      return <div className="control">
                        <div className="s-label">{fil}</div>
                        <div className="item-control">
                          <FormControl className="select-form-control">
                            <Select
                              variant="outlined"
                              value={value}
                              onChange={(e) => {
                                if (index == 0) { setDynamicFilter({ ...defaultStates }) }
                                if (e.target.value.length == 0) {
                                  let obj = filterKeyValues(dynamicFilter, index)
                                  if (obj[fil]) { delete obj[fil] }
                                  setDynamicFilter({ ...defaultStates, obj })
                                }
                                else
                                  setDynamicFilter((old) => ({ ...old, [fil]: e.target.value }))
                              }}
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                            >
                              {!defaultStates?.[fil] && <MenuItem value=''>All</MenuItem>}
                              {options
                                .sort((a, b) => a.localeCompare(b))
                                .map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    })}
                  </div>
                  {toogleTable ? Object.keys(toogleTableData || {}).length > 0 &&
                    <CustomTable
                      rows={toogleReturnRows(toogleTableData?.[currentView])}
                      headCells={processHead('arr', toogleReturnRows(toogleTableData?.[currentView])?.[0]) || []}
                      emptyColumn={pageConfig.emptyColumn}
                      emptyCharacter={pageConfig.emptyCharacter || ""}
                      defaultOrder={"desc"}
                      ignoreColumns={pageConfig?.ignoreColumns}
                      defaultOrderBy={"Total Participation"}
                      grandTotalColumns={pageConfig?.grandTotalColumns || []}
                      leftAlignColumn={["Super Trainer"]}
                      showRowsPerPage={pageConfig?.showRowsPerPage || 25}
                      showcolor1={pageConfig?.showColor1}
                      showColor={pageConfig?.showColor}
                      percentageColumn={pageConfig?.percentageColumn}
                      disableSortColumn={pageConfig?.disableSortColumn}
                      columnWidth={pageConfig?.columnWidth}
                      page={page}
                      emptyInfo={0}
                    /> :
                    <CustomTable
                      page={page}
                      rows={toogleReturnRows(summary)}
                      headCells={headCells}
                      ignoreColumns={pageConfig?.ignoreColumns}
                      emptyColumn={pageConfig.emptyColumn}
                      emptyCharacter={pageConfig.emptyCharacter || ""}
                      defaultOrder={"desc"}
                      defaultOrderBy={"Total Participation"}
                      grandTotalColumns={pageConfig?.grandTotalColumns || []}
                      leftAlignColumn={["Super Trainer"]}
                      showRowsPerPage={pageConfig?.showRowsPerPage || 25}
                      showcolor1={pageConfig?.showColor1}
                      showColor={pageConfig?.showColor}
                      percentageColumn={pageConfig?.percentageColumn}
                      disableSortColumn={pageConfig?.disableSortColumn}
                      columnWidth={pageConfig?.columnWidth}
                      emptyInfo={pageConfig?.emptyInfo}
                    />}
                </div>
              )}
              <CustomModal open={open.state} handleClose={() => setOpen({ 'state': false })}>
                <div>
                  <p
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(open.value), }}
                    style={{ fontSize: "14px" }}
                  />

                  <div
                    style={{
                      margin: "25px 0",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={() => setOpen(!open)}
                      variant="contained"
                      style={{
                        borderRadius: "25px",
                        padding: "5px 25px",
                        backgroundColor: "#FFBC36",
                      }}
                    >
                      View Report
                    </Button>
                  </div>
                </div>
              </CustomModal>
            </div>
          </section >
        </div >
      );
    }
  }
  return <div style={{ height: "100%", width: "100%" }}>{retFun()}</div>;
}

export default MasterTrainer;
