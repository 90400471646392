import { Accordion, AccordionDetails, AccordionSummary, Button, TextField, makeStyles } from "@material-ui/core";
import { ArrowBack, ArrowRight, ExpandMore } from "@material-ui/icons";
import "./style.scss";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { useState } from "react";

const useStyles = makeStyles((theme) => ({

    inputFilled: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        // background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
        },
        "&:hover .MuiOutlinedInput-root": {
            borderColor: 'black'
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: 'black'
        }
    },

}));


const BatchWise = ({ rows, report, setReport, Custom_Table, processHead }) => {
    console.log('asd', JSON.parse(JSON.stringify(rows)))
    const [search, setSearch] = useState('')
    const handleClose = () => { setReport(null) };
    let [expanded, setExpaned] = useState(true)
    var batchReport = []
    const classes = useStyles();

    let a = rows.map(trainer => {
        if (trainer['Master Trainer Name'] == report && trainer['batchReport']) {
            console.log(trainer['Master Trainer Name'] == report, trainer['Master Trainer Name'], report)
            batchReport = trainer['batchReport']
        }
    })[0]
    let headCell = batchReport?.length > 0 && processHead('arr', batchReport[0])

    console.log({ headCell, batchReport, rows })

    if (batchReport?.length > 0)
        return <div style={{ display: 'flex', gap: '1rem', padding: 5 }}>
            <div style={{ display: 'flex', flexDirection: 'column', }}>
                <Button
                    onClick={handleClose}
                    className="close-btn"
                    startIcon={<ArrowBack />}
                    style={{ backgroundColor: '#E8E8E8', marginBottom: '10px' }}
                >
                    Back
                </Button>

                <Accordion defaultExpanded={true} expanded={expanded} style={{ background: '#EEE', padding: 0 }}>
                    <AccordionSummary
                        style={{ paddingLeft: 0 }}
                        expandIcon={<ExpandMore onClick={() => setExpaned(!expanded)} style={{ color: "#ba1f00", padding: "0" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <span className="MAsterAcc" >
                            <TextField
                                variant="outlined"
                                InputProps={{ classes: { root: (true) ? classes.inputFilled : classes.inputEmpty } }}
                                onChange={(ev) => setSearch(ev.target.value)}
                                placeholder="Search"
                            />
                        </span>
                    </AccordionSummary>
                    <AccordionDetails style={{ background: '#f7f7f7', display: 'flex', flexDirection: 'column', maxHeight: '60vh', overflowY: 'auto', padding: 0 }}>
                        {rows
                            .filter(r => Object.keys(r?.['batchReport']?.[0])?.length > 0)
                            .filter(r => search?.length > 0 ? r?.['Master Trainer Name']?.toLowerCase()?.includes(search?.toLowerCase()) : true)
                            .map(r => {
                                let isActive = report == r['Master Trainer Name']
                                return <Typography onClick={() => setReport(r['Master Trainer Name'])} className={`masterNameDiv`}>
                                    <ArrowRight
                                        fontSize="large"
                                        style={{ fill: isActive ? "#BA1F00" : 'transparent' }}

                                    />
                                    {r['Master Trainer Name']}
                                </Typography>
                            })}

                    </AccordionDetails>
                </Accordion>

            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ color: '#B40000', fontSize: 500, fontSize: 20, width: '60%', }}>Batch wise trainer performance
                    </div>
                    {/* <Button variant="contained">Download</Button> */}
                </div>
                {batchReport?.length > 0 && headCell?.length > 0 && <Custom_Table
                    rows={batchReport}
                    headCells={headCell}
                />}
            </div>
        </div>
    else {
        return null
    }

}

export default BatchWise;