import { useEffect, useContext, useState } from "react";
import { AppStateContext } from "../../providers/app.provider";
import { useHistory } from "react-router-dom";
import { loginWithToken } from "../../services/auth";
import { CircularProgress } from "@material-ui/core";
import { LOGGED_IN } from "../../constants/app.constant";

export default function AutoLogin(props) {
  let auto_login_token = props.match.params.token;
  const [, dispatch] = useContext(AppStateContext);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();

  const handleAutoLogin = (token) => {
    setIsLoading(true);
    loginWithToken(token)
      .then((res) => {
        setIsLoading(false);
        if (res && res.data) {
          history.push("/reports");
          dispatch({ type: LOGGED_IN, payload: res.data })
        };
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  useEffect(() => auto_login_token && handleAutoLogin(auto_login_token), [auto_login_token]);

  if (isLoading) {
    return <CircularProgress />;
  } else {
    return (
      <div>
        <button
          hidden
          onClick={() => {
            handleAutoLogin(auto_login_token);
          }}
        >
          Login
        </button>
        <p>Logout And Re-Login</p>
      </div>
    );
  }
}
