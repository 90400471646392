import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { GetApp, InsertDriveFile, PictureAsPdf } from "@material-ui/icons";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useEffect } from "react";
import Export from "../../partials/export";
export default ({ rows, filename, headCells, grandTotalColumns, emptyInfo }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        Object.entries(rows).forEach((_, index) => {
            Object.values(headCells).forEach(cell => {
                return rows[index][cell.originalKey] = (rows[index][cell.originalKey] == emptyInfo) ? "" : `${rows[index][cell.originalKey]}`
            })
        })

        if (grandTotalColumns?.length > 0) {
            const out2 = {}
            for (const cell of headCells) {
                if (grandTotalColumns?.includes(cell.originalKey)) {
                    out2[cell.originalKey] = rows.reduce((total, currentValue) => total + (
                        currentValue[cell.originalKey] != "" ? parseInt(currentValue[cell.originalKey], 10) : 0), 0)
                }
                else {
                    out2[cell.originalKey] = ""
                }
            }

            rows.push({ ...out2, [[headCells?.[0]?.originalKey]]: "Total" })
        }
    }, [rows])

    const returnTitle = () => {
        return document.querySelector(".caption")?.textContent;
    };

    return <div className="close-btn-w" style={{ width: "fit-content" }}>
        <div className="download-control">
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Button
                            {...bindTrigger(popupState)}
                            id="basic-button"
                            className="download-btn"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            startIcon={<GetApp />}
                        >
                            download report
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={popupState.close} className="menu-item">
                                <Export
                                    element={
                                        <React.Fragment>
                                            <ListItemIcon>
                                                <InsertDriveFile fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>Export as Excel</ListItemText>
                                        </React.Fragment>
                                    }
                                    data={rows}
                                    filename={`${filename}.xlsx`}
                                    SheetName="Sheet 1"
                                    title={returnTitle()}
                                />{" "}
                            </MenuItem>
                            {/* <MenuItem
                                onClick={() => {
                                    popupState.close();
                                    GenericPdfDownloader("table", filename, headCells, filter(rows), returnTitle());
                                }}
                            >
                                <ListItemIcon>
                                    <PictureAsPdf fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Export as PDF</ListItemText>
                            </MenuItem> */}
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
        </div>
    </div>
}