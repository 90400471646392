import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { visuallyHidden } from "@mui/utils";

function CustomTablehead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    ignoreHeads,
    showColor,
    boldColumn,
    disableSortColumn,
    columnWidth,
  } = props;
  // console.log("headCells", headCells);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getCellAlign = (headCell, index) => {
    // console.log("headeCell", headCell);
    if (showColor?.[headCell.label]) return "center";
    else if (headCell.numeric) return "left";
    // else if (boldColumn?.includes(headCell.label))
    //   return "center"
    else if (!headCell.numeric) return "center";
    else return "left";
  };

  return (
    <TableHead className="custom-table-head">
      <TableRow>
        {headCells
          .filter((headCell) => !ignoreHeads.includes(headCell.label))
          .map((headCell, index) => {
            const hideSort = disableSortColumn.includes(headCell.label);
            return (
              <TableCell
                key={headCell.id}
                // marginLeft={headCell.numeric ?"0":"0.4rem"}
                align={getCellAlign(headCell, index)}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                style={{
                  lineHeight: "1.2",
                  backgroundColor: "white",
                }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={hideSort ? () => { } : createSortHandler(headCell.id)}
                  hideSortIcon={hideSort}
                  style={hideSort ? { cursor: "default" } : {}}
                >
                  <span
                    style={{
                      marginLeft: showColor?.[headCell.label] ? "5%" : "0%",
                      width: columnWidth?.[headCell.label] ? "110px" : "",
                      fontSize: '0.9rem'
                    }}
                  >
                    {headCell.label}
                    <div style={{ fontSize: ".8rem", color: "#999999" }}>
                      {headCell.caption}
                    </div>
                  </span>

                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
}

CustomTablehead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
  ignoreHeads: PropTypes.array,
};

CustomTablehead.defaultProps = {
  ignoreHeads: [],
};

export default CustomTablehead;
